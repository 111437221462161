// src/firebase-handler.js
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    signInWithCredential,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signOut,
} from 'firebase/auth';
import { getRedirectResult, signInWithRedirect, isSignInWithEmailLink } from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCeAq0i1WGLviKAQlD6P0lhx3nqHGvz7OA',
    authDomain: 'partytrivia-e27c0.firebaseapp.com',
    databaseURL: 'https://partytrivia-e27c0.firebaseio.com',
    projectId: 'partytrivia-e27c0',
    storageBucket: 'partytrivia-e27c0.appspot.com',
    messagingSenderId: '242906059254',
    appId: '1:242906059254:web:b1ba331e6bf005946777d7',
    measurementId: 'G-0ZEQP1JJ68',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();


export const signInWithGoogle = async onError => {
    try {
        console.log(`[signInWithGoogle] attempting to login with google`);
        const response = await signInWithPopup(auth, googleProvider)
            .then(result => {
                console.log(`[signInWithGoogle] Success got the result:`, result);
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                console.log(`[signInWithGoogle] got the token: ${token}`);
                // IdP data available using getAdditionalUserInfo(result)

                const user = result.user;
                console.log(`[signInWithGoogle] User signed in with UID: ${user.uid}`);
            })
            .catch(error => {
                console.error(`[signInWithGoogle] Error: ${error}`);
            });
        console.log(`[signInWithGoogle] response: ${response}`);
    } catch (error) {
        console.log(`[signInWithGoogle] error: ${error}`);
        if (onError != undefined) {
            onError(error.message);
        }
    }
};

export const SignOutAndClearAllData = async () => {
    signOut(auth).then(() => {
      // Sign-out successful.
          console.log("Signed out successfully")
          console.log(`Triggered ClearAllData`);
          localStorage.clear();
          window.location.reload();
      }).catch((error) => {
        console.log("could not sign out")
        console.log(`Triggered ClearAllData`);
        localStorage.clear();
        window.location.reload();
      });
      
  };

export const signInWithFacebook = async onError => {
    window.FB.login(
        response => {
            if (response.authResponse) {
                const { accessToken } = response.authResponse;

                // Use the access token to create a Firebase credential
                const credential = FacebookAuthProvider.credential(accessToken);
                const auth = getAuth();
                signInWithCredential(auth, credential)
                    .then(userCredential => {
                        // User signed in
                        console.log('User signed in:', userCredential.user);
                    })
                    .catch(error => {
                        console.error('Error signing in:', error);
                    });
            } else {
                console.error('User cancelled login or did not fully authorize.');
            }
        },
        { scope: 'email' },
    );
};

// Add this to handle the result after redirect
export const handleRedirectResult = async () => {
    try {
        const result = await getRedirectResult(auth);
        if (result) {
            console.log(`[handleRedirectResult] Success got the result:`, result);
            const credential = FacebookAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            console.log(`[handleRedirectResult] got the token: ${accessToken}`);

            const user = result.user;
            console.log(`[handleRedirectResult] User signed in with UID: ${user.uid}`);
        } else {
            console.log(`[handleRedirectResult] No result found from redirect`);
        }
    } catch (error) {
        console.error(`[handleRedirectResult] Error: ${error}`);
    }
};

export const CheckLoggedIn = () => {
    return new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                console.log('[CheckLoggedIn] User is signed in, uid:', user.uid);
                resolve(true);
            } else {
                console.log('[CheckLoggedIn] User is logged out');
                resolve(false);
            }
        });
        // Optional: Unsubscribe to avoid memory leaks, though this will be called on component unmount
        return unsubscribe;
    });
};


export const isLoggedIn = () => {
    const loggedIn = auth.currentUser !== null;
    console.log('[isLoggedIn] User is signed in:', loggedIn);
    return loggedIn;
};

export default app;
