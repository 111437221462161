import React, { Fragment, useEffect, useRef } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import { SignOutAndClearAllData } from '../firebase/firebase-handler';
import { ModalInputWrapper, openInputField, closeInputField } from '../modal/ModalInput';
import VersionNumber from '../util/VersionNumber';

const GamePage = () => {
    const initializedRef = useRef(false);
    const unityContainerRef = useRef(null);
    const unityCanvasRef = useRef(null);
    
    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener, requestFullscreen } = useUnityContext({
        loaderUrl: "unity_build/partytrivia-web-app.loader.js",
        dataUrl: "unity_build/partytrivia-web-app.data.unityweb",
        frameworkUrl: "unity_build/partytrivia-web-app.framework.js.unityweb",
        codeUrl: "unity_build/partytrivia-web-app.wasm.unityweb",

        streamingAssetsUrl: "StreamingAssets",
    });

    const onUnityLogOut = (json) => {
        console.log(`[onUnityLogOut] got logout request with the json: ${json}`);
        SignOutAndClearAllData();
    };

    const onUnityCopyToClipboard = (text) => {
        console.log(`[UnityCopyToClipboard] unity request to CopyToClipboard with the text: ${text}`);
        navigator.clipboard.writeText(text)
        .then(() => {
            console.log(`Copied text to clipboard: ${text}`);
            alert(`Copied text to clipboard: ${text}`);
        })
        .catch((error) => {
            console.error(`Could not copy text: ${error}`);
        });
    };

    const onUnityVibrate = (json) => {
        var value = JSON.parse(json);
        console.log(`[onUnityVibrate] unity request to vibrate with value: ${value}`);
        if (navigator.vibrate) {
            navigator.vibrate(value);
        }
    };

    const onUnityVibratePattern = (json) => {
        // json should be in for the format of = { "patternArray": int[], "length": int}
        var value = JSON.parse(json);
        var patternArray = value?.patternArray;
        var length = value?.length;
        console.log(`[onUnityVibrate] unity request to vibrate pattern with patternArray: ${patternArray}, with lenght: ${length}`);
        
        if (navigator.vibrate) {
            var pattern = Array.from({length: length}, (v, i) => module.HEAP32[patternArray / 4 + i]);
            navigator.vibrate(pattern);
        }
    };

    const onUnityRequestPlayerInfo = (json) => {
        //TODO: Get player info from firebase auth
        const playerInfo = { 
            "id" : "todo",
            "isGuest" : false,
            "cached_joincode" : localStorage.getItem("SavedJoinCode"),
            "token" : "guestToken", 
            "nickname" : "todo",
            "autoplay" : localStorage.getItem("UseAutoPlay") === 'true',
            "version" : "0.0.0b",
        };
        sendMessage('UnityToWeb', 'ProcessWebConfig', JSON.stringify(playerInfo)); 
    };

    const onUnityOpenInputField = (json) => {
        const data = JSON.parse(json);
        console.log(`[onUnityOpenInputField] unity requested the input field with the data: ${JSON.stringify(data)}`);
        openInputField(data.title, data.description, data.prefix, data.value, UnityOnInputFieldComplete);
    };

    const onUnityCloseInputField = (json) => {
        const data = JSON.parse(json);
        console.log(`[onUnityCloseInputField] unity requested to close the input field`);
        closeInputField();
    };

    const UnityOnInputFieldComplete = (value) => {
        console.log('[UnityOnInputFieldComplete] Input value:', value);
        //sendMessage('UnityToWeb', 'OnInputFieldComplete', value); 
    }

    const onUnityToggleFullscreen = (json) => {
        var value = JSON.parse(json);
        console.log(`[onUnityToggleFullscreen] unity requested to toggle fullscreen with value: ${value}`);
        requestFullscreen(value);
    };

    const handleModalInputActive = () => {
        console.log('handleModalInputActive');
        if (unityCanvasRef.current) {
            unityCanvasRef.current.blur();
        }
    };

    const handleModalInputClosed = () => {
        console.log('handleModalInputClosed');
        if (unityCanvasRef.current) {
            unityCanvasRef.current.focus();
        }
    };

    useEffect(() => {
        window.addEventListener('onModalInputActive', handleModalInputActive);
        window.addEventListener('onModalInputClosed', handleModalInputClosed);

        return () => {
            window.removeEventListener('onModalInputActive', handleModalInputActive);
            window.removeEventListener('onModalInputClosed', handleModalInputClosed);
        };
    }, []);

    useEffect(() => {
        let wakeLock = null;

        const requestWakeLock = async () => {
            try {
                wakeLock = await navigator.wakeLock.request('screen');
                console.log('[requestWakeLock] Wake Lock activated');
            } catch (error) {
                console.warn('[requestWakeLock] Failed to activate Wake Lock:', error);
            }
        };

        // Request wake lock when component mounts
        requestWakeLock();

        // Release wake lock when component unmounts
        return () => {
            if (wakeLock !== null) {
                wakeLock.release();
                console.log('Wake Lock released');
            }
        };
    }, []);

    useEffect(() => {
        console.log(`[GamePage] isLoaded has changed: ${isLoaded}`);
        if (isLoaded) {
            console.log('[GamePage] Unity has completed loading');
            if (!initializedRef.current) {
                // Ensure that initialization occurs only once
                console.log('[GamePage.UnityAttachment] Attempting to attach rmbclient to unity');
                
                // Attach Unity to Javscript Event Listeners
                addEventListener("UnityLogOut", onUnityLogOut);
                addEventListener("UnityRequestWebConfig", onUnityRequestPlayerInfo);
                addEventListener("UnityCopyToClipboard", onUnityCopyToClipboard);
                addEventListener("UnityVibrate", onUnityVibrate);
                addEventListener("UnityVibratePattern", onUnityVibratePattern);
                addEventListener("UnityOpenInputField", onUnityOpenInputField);
                addEventListener("UnityCloseInputField", onUnityCloseInputField);
                addEventListener("UnityToggleFullscreen", onUnityToggleFullscreen);
                
                console.log('[GamePage.UnityAttachment] Finished attaching rmbclient to unity');
                
                // Finished setup
                initializedRef.current = true;
                console.log('[GamePage.UnityAttachment] completed unity setup');
                
                // Pass playerInfo
                onUnityRequestPlayerInfo("");
            }
        }

        return () => {
            console.log(`[GamePage.UnityAttachment] exiting with ${initializedRef.current}`);
            if (initializedRef.current) {
                // Put all clean up under logic here (if needed)
                removeEventListener("UnityLogOut", onUnityLogOut);
                removeEventListener("UnityRequestPlayerInfo", onUnityRequestPlayerInfo);
                removeEventListener("UnityCopyToClipboard", onUnityCopyToClipboard);
                removeEventListener("UnityVibrate", onUnityVibrate);
                removeEventListener("UnityVibratePattern", onUnityVibratePattern);
                removeEventListener("UnityOpenInputField", onUnityOpenInputField);
                removeEventListener("UnityCloseInputField", onUnityCloseInputField);
                removeEventListener("UnityToggleFullscreen", onUnityToggleFullscreen);
                console.log('[GamePage.UnityAttachment] Cleared all attachments from rmbclient to unity');
                initializedRef.current = false;
            }
        };
    }, [isLoaded]);

    return (
        <Fragment>
            {!isLoaded && (
                <div>
                    <div className="center center-boarder">
                        <p>Joining Game... {Math.round(loadingProgression * 100)}%</p>
                    </div>
                </div>
            )}
            
            <div className="unity-container" ref={unityContainerRef}>
                <Unity
                    unityProvider={unityProvider}
                    className="unity-content"
                    ref={unityCanvasRef}
                    style={{ visibility: isLoaded ? "visible" : "hidden" }}
                />
                <ModalInputWrapper></ModalInputWrapper>
            </div>
        </Fragment>
    );
};
export default GamePage;