import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignOutAndClearAllData } from '../firebase/firebase-handler';
import buildNumber from '../version_number.json';

export const GetVersionNum = (()=> {
  return `v${buildNumber.major}.${buildNumber.minor}.${buildNumber.patch}b`
})

export const VersionNumber = ({ onClicksMetCallback }) => {
  const [clickCount, setClickCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (clickCount >= 1) {
      const timer = setTimeout(() => {
        setClickCount(0);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [clickCount]);

  const handleTextClick = () => {
    if (clickCount < 10) {
      setClickCount(prevCount => prevCount + 1);
    } else {
      setShowPopup(true);
    }
  };

  return (
    <div>
      <div style={{ position: 'relative', height: '100vh' }}>
        <p
          className="version-text noselect"
          onClick={handleTextClick}
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '10px',
            cursor: 'pointer',
            color: '#ffffffbf', // Semi-transparent white
            fontSize: '12px', // Shrink the font size
            userSelect: 'none', // Prevent text selection
          }}
        >
          {GetVersionNum()}
        </p>
        {showPopup && (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '20px',
              background: '#fff',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
              zIndex: '999',
            }}
          >
            <h2 style={{ color: 'rgba(0, 0, 0, 0.7)' }}>Admin</h2>
            <button onClick={SignOutAndClearAllData}>Clear all data</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VersionNumber;
